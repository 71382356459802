/** @jsx jsx */
import { jsx } from '@emotion/core';
import cmsUtils from '../../cms/utils/cmsUtils';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Loading } from '../../components';
import style from './style';

export function JotformLightbox(props) {

    const item = props.item;
    const cssClass = props.cssClass;
    const code = cmsUtils.payload(item, 'Code') || '';
    const codes = code.split("</_script>");

    const [result, setResult] = useState();
    console.log("codes --->", codes);

    useEffect(() => {

        const javsScriptCodes = [];
        let nonjavaScriptCode = "";
        let index = 1;

        codes.forEach(code => {

            if (code.includes("<_script")) {
                const script = document.createElement('script');
                script.type = `text/javascript`;
                script.id = 'jotformLightbox' + index;

                const javaScriptTopCode = code.substring(code.indexOf("<_script"), (code.indexOf(">") + 1)).trim().replace("src =", "src=").replace("src = ", "src=").replace("src= ", "src=").trim();
                const javaScriptInsideCode = code.substring((code.indexOf(">") + 1)).trim();

                console.log("javaScriptTopCode", javaScriptTopCode);

                if (javaScriptTopCode.includes("src=")) {
                    let _src = javaScriptTopCode.indexOf('src="') === -1 ? '' : javaScriptTopCode.substring(javaScriptTopCode.indexOf('src="')).trim();
                    let src = _src.substring((_src.indexOf('src="') + 5), _src.indexOf('"', 5));
                    script.src = src;
                }

                if (javaScriptInsideCode !== '') {
                    script.text = javaScriptInsideCode;
                }
                javsScriptCodes.push(script);
            }
            else {
                nonjavaScriptCode = code.trim();
            }

            index++;

        });

        setResult({ javsScriptCodes: javsScriptCodes, nonjavaScriptCode: nonjavaScriptCode });

        // eslint-disable-next-line
    }, []);

    return (
        <div className={cssClass} id={item.anchorName}>
            {
                result ? <GetJotformLightbox result={result} /> : <Loading />
            }
        </div>
    )
}


function GetJotformLightbox({ result }) {

    const javsScriptCodes = result.javsScriptCodes;
    const nonjavaScriptCode = result.nonjavaScriptCode;
    const [index, setIndex] = useState(0);

    useLayoutEffect(() => {

        if (!javsScriptCodes || (javsScriptCodes && javsScriptCodes.length <= 0)) {
            return;
        }

        const existingScript = document.getElementById(javsScriptCodes[index].id);
        if (existingScript) {
            document.body.removeChild(existingScript);
        }
        document.body.appendChild(javsScriptCodes[index]);

        let timer = null;

        if (javsScriptCodes.length > (index + 1)) {
            timer = setTimeout(() => {
                setIndex(index + 1);
            }, 1000);
        }

        return () => {
            if(timer){
                clearTimeout(timer);
            }
        }

        // eslint-disable-next-line
    }, [index]);

    return (
        <div dangerouslySetInnerHTML={{ __html: nonjavaScriptCode }} css={style.jotform__lightbox__container}></div>
    )
}