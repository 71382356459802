//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
    
    photoGallery : css({
        maxWidth : '960px',
        margin: 'auto'
    }),
    
    photos : css({
        marginLeft: '-5px',
        marginRight: '-5px',
        '& > div' : {
            display: 'flex',
            flexWrap: 'wrap'
        }
        // display: 'flex',
        // flexWrap: 'wrap',
        // marginLeft: '-5px',
        // marginRight: '-5px',
        // '& img' : {
        //     flex: '1 1 150px',
        //     margin: '5px',
        //     paddingTop : '60%',
        //     width : '150px',
        //     border: '1px solid red'
        // }
    }),
    // photoContainer : css({
    //     //color : 'red',
    //     flex: '1 1 150px',
    //     margin : '5px',
    //     height : '150px',
    //     display: 'flex',
    //     overflow: 'hidden',
    //     cursor:'pointer'
    // }),
    photoContainer : css(mq({
        //color : 'red',
        flexGrow: '1',
        flexShrink: '1',
        flexBasis: ['160px', '150px'],
        //flexBasis: ['145px', '150px'],
        maxWidth:['167px', '150px'],
        margin : '5px',
        height : '150px',
        display: 'flex',
        overflow: 'hidden',
        cursor:'pointer'
    })),
    photo : css({
        margin : 'auto',
        transform: 'scale(3)'
    }),
    photoEmpty : css({
        flex: '1 1 150px',
        height : '0px'
    })
}