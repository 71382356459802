import { css  } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
  top: css(mq({
    height: [44, null, 56],
    background: '#5daab9',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  // topScrolled: css(mq({
  //   height: [44, null, 56],
  // })),
  logo: css(mq({
  })),
  logoImg: css(mq({
    display: 'block',
    height: [60,null,80,100],
    marginTop:['2rem',null,'2.5rem','3rem'],
    transition:'all 0.5s'
  })),
  logoScrolled: css(mq({
    'img' : css(mq({
      height: [38,null,50],
      marginTop:'0px'
    }))
  })),
}