import { css } from '@emotion/core';
import { mq } from '../../cssInJs';
export default {
  button: css(mq({
    position: 'absolute',
    left: '1rem',
    lineHeight: '1.6rem',
    color: '#ddd',
    fontSize: ['0.7rem', null, '0.7rem'],
    'svg' : css({
      marginBottom:'1px'
    })
  }))
}