/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect } from 'react';
//import cmsUtils from '../../utils/cmsUtils';
//import utils from '../../utils';

export function TripavisorWidgets(props){
    //const item = props.item;
    //const subItems = item.items || [];
    //const cmsOption = props.cmsOption || {};
    //const cssClass = utils.classNames('cms_item', 'tripavisorWidgets', item.cssClass || item.anchorName || '');
    //const bw = useRef(null);
    
    useEffect(() => {

        delete window.taValidate;
        const existingScript = document.getElementById('tripavisorWidgets');
        if(existingScript){
            document.body.removeChild(existingScript);
        }
        const script = document.createElement('script');
        //script.src = 'https://www.tripadvisor.com.au/WidgetEmbed-cdsratingsonlywide?border=true&locationId=15308399&display_version=2&uniq=109&lang=en_AU';
        script.src = 'https://www.jscache.com/wejs?wtype=cdsratingsonlywide&uniq=109&locationId=15308399&lang=en_AU&border=true&display_version=2';     
        script.id = 'tripavisorWidgets';
        script.async = true;
        script.setAttribute("data-loadtrk", true);
        script.onload = function(){
            //window[functionName]();
            this.loadtrk=true;
            setTimeout(function() {
                console.log(window.taValList)
                if (window.taValList) {
                for (let ii = 0; ii < window.taValList.length; ii=ii+1) {
                    const fname = window.taValList[ii]
                    fname();
                 }
                }
            }, 1000)
        };
        //window.addEventListener('load', function() { console.log('loaded..')})
        document.body.appendChild(script);
        // setTimeout(() => {
        //     const iframe = bw.current.querySelector('iframe');
        //     iframe.style.minWidth = 'unset';
        //     //iframe.style.minHeight = '900px';
        // }, 1000);
    }, []);
    
    return(
        <div style={{margin: '1rem 0px', textAlign:'center'}}>
        <div id="TA_cdsratingsonlywide109" className="TA_cdsratingsonlywide">
                    <ul id="2Yt131JDm" className="TA_links 6i7LjauIW8" style={{listStyleType:'none', paddingInlineStart:'0px'}}>
                        <li id="3wjMCoTy" className="v3Ol0P">
                            <a target="_blank" rel="noopener noreferrer" href="https://www.tripadvisor.com.au/Restaurant_Review-g495041-d15308399-Reviews-North_Beach_Kitchen-Wallaroo_South_Australia.html">
                                <img style={{width:'100px'}} src="https://www.tripadvisor.com.au/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-18034-2.svg" alt="TripAdvisor"/>
                            </a>
                        </li>
                    </ul>
        </div>
        </div>
    );
}