export function isBlank(str) {
  return (!str || /^\s*$/.test(str))
}

export function isEmpty(str) {
  return (!str || str.length === 0)
}

export function capitalize(expression) {
  return expression.charAt(0).toUpperCase() + expression.slice(1);
}

export function toFieldName(str) {
  if (!str) return null;
  return capitalize((str.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1')).trim());
}

export function toHtml(str) {
  if (!str) return null;
  return str.replace(/[\n\r]/g, '<br/>')
}

// Shorten a string to less than maxLen characters without truncating words.
export function truncateWords(str, maxLen, separator = ' ', ellipsis = '...') {
  if (str.length <= maxLen) return str
  var pos = str.lastIndexOf(separator, maxLen)
  return str.substr(0, pos) + ellipsis
}

export function numberWithCommas(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}
