import * as React from 'react';
import ValidationAlert from '../components/ValidationAlert';
import utils from '../../../utils';
import { getLabel } from '../formRenderHelper';

function FileInput({ control, handleChange, formOption }) {
    const { name, label, type, value, errors, style } = control;
    //const { template = 'bootstrap', usePlaceholder = false } = formOption;
    const fieldId = utils.random.shortId();
    const { usePlaceholder = false } = formOption;
    const fieldLabel = getLabel(control, formOption);
    //console.log(label, control)
    const placeHolder = usePlaceholder ? fieldLabel : null;
    let valueStr = value === 0 ? 0 : (value || "");
    if (type === 'date' && value) {
        valueStr = utils.date.formatYYYYMMDD(value);
    }
    return (
        <div className={`ff ff--file form-group ${style && style.className}`}>
            {!usePlaceholder && fieldLabel && <label htmlFor={fieldId} className="label">{fieldLabel}</label>}
            <div className="ff__input">
                <input id={fieldId} name={name} type='file' value={valueStr} onChange={(e) => handleChange(e, control)} onBlur={(e) => handleChange(e, control)}
                    placeholder={placeHolder}
                    className={"form-control-file" + (errors && errors.length > 0 ? ' is-invalid' : '')} />
            </div>
            <ValidationAlert errors={errors} />
        </div>

    )
}

export default FileInput;