import validators from './validators';

export function validate(root, validateOpt = {}) {
  //console.log({...{ triggeredByChange: false }, ...validateOpt})
  const errors = validateTraverse(root, {...{ triggeredByChange: false }, ...validateOpt});
  return errors;
}

function validateTraverse(control, validateOpt) {
  if (!control) return [];
  const errors = [];
  const errorsFromControl = validators.validateControl(control, null, validateOpt);
  errors.push(...errorsFromControl);
  //console.log(errorsFromControl, errors)
  if (control.controls && control.controls.length > 0) {
    for (let i = 0; i < control.controls.length; i++) {
      const errorsFromSub = validateTraverse(control.controls[i], validateOpt);
      errors.push(...errorsFromSub);
    }
  }
  control.errors = errors;
  return errors;
}

